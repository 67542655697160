import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { UserProvider, useUser } from './contexts/UserContext';
import Layout from './components/Layout';
import HomeView from './views/HomeView';
import LoginView from './views/LoginView';
import ProfileView from './views/ProfileView';
import AdminView from './views/AdminView';
import SignUpView from './views/SignupView';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, isLoading } = useUser();
  if (isLoading) {
    return (
      <div className="container mx-auto w-full py-4 sm:px-6 lg:px-4 lg:py-6"></div>
    );
  }
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return <>{children}</>;
};

const ProtectedAdminRoute = ({ children }: { children: React.ReactNode }) => {
  const { role, isLoading } = useUser();
  if (isLoading) {
    return (
      <div className="container mx-auto w-full py-4 sm:px-6 lg:px-4 lg:py-6">
        <div className="flex justify-between items-center">Loading...</div>
      </div>
    );
  }
  if (role !== 'admin') {
    return <Navigate to="/" replace />;
  }
  return <>{children}</>;
};

function App() {
  return (
    <React.StrictMode>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <HomeView />
                  </ProtectedRoute>
                }
              />
              <Route path="login" element={<LoginView />} />
              <Route path="signup" element={<LoginView />} />
              <Route path="/profile/:username" element={<ProfileView />} />
              <Route
                path="admin"
                element={
                  <ProtectedAdminRoute>
                    <AdminView />
                  </ProtectedAdminRoute>
                }
              />
              {/* Catch-all route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </Router>
      </UserProvider>
    </React.StrictMode>
  );
}

export default App;
