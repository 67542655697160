import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { FaLemon, FaRegUserCircle } from 'react-icons/fa';

interface UserDropdownProps {
  isOpen: boolean;
  onClose: () => void;
}

const NavUserDropdownMenu: React.FC<UserDropdownProps> = ({
  isOpen,
  onClose,
}) => {
  const { user, logout, role } = useUser();
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      ref={menuRef}
      className="fixed top-0 left-0 w-full bg-white shadow-md text-center border-b border-gray-200 transform transition-transform duration-300 ease-in-out z-50"
      style={{
        transform: isOpen ? 'translateY(0)' : 'translateY(-100%)',
      }}
    >
      <nav className="">
        <div className="container mx-auto w-full p-4 sm:px-6 lg:px-4 lg:py-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <Link
                to="/"
                className="text-md font-bold flex items-center"
                onClick={onClose}
              >
                <div className="flex">
                  <FaLemon className="text-xl text-yellow-100 h-4 w-4 mr-1" />
                  <FaLemon className="text-xl text-yellow-200 h-4 w-4 mr-1" />
                  <FaLemon className="text-xl text-yellow-300 h-4 w-4 mr-1" />
                  <FaLemon className="text-xl text-yellow-400 h-4 w-4" />
                </div>
              </Link>
            </div>
            <ul className="flex space-x-4 text-xs font-semibold">
              {role === 'admin' && (
                <li>
                  <Link
                    to="/admin"
                    className="hover:text-gray-200"
                    onClick={onClose}
                  >
                    <div className="flex items-center h-full">Admin Panel</div>
                  </Link>
                </li>
              )}
              <li>
                <div
                  className="flex items-center h-full text-gray-600 hover:text-gray-600 cursor-pointer"
                  onClick={onClose}
                >
                  {user?.username}
                  <FaRegUserCircle className="h-5 w-5 text-gray-600 ml-2" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mx-auto px-4 py-8">
        <nav>
          <ul className="space-y-4">
            <li>
              <Link
                to={`/profile/${user?.username}`}
                className="hover:text-blue-800 text-md font-semibold"
                onClick={onClose}
              >
                View Profile
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  logout();
                  onClose();
                }}
                className="hover:text-red-800 text-md font-semibold"
              >
                Logout
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default NavUserDropdownMenu;
