import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useFeathers } from '../hooks/useFeathers';
import ProfileSidebar from '../components/ProfileSidebar';
import EditableField from '../components/EditableField';

interface UserProfile {
  _id: string;
  email: string;
  username: string;
  role?: 'user' | 'admin';
  firstName?: string;
  lastName?: string;
  profilePicture?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

const ProfileView: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const { user: currentUser, role } = useUser();
  const { client } = useFeathers();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await client
          .service('users')
          .find({ query: { username } });
        if (response.data && response.data.length > 0) {
          setUserProfile(response.data[0]);
        } else {
          setError('User not found');
        }
      } catch (err) {
        console.error('Error fetching user profile:', err);
        setError('Failed to load user profile');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [client, username]);

  const handleSave = useCallback(
    async (field: string, value: string): Promise<string | null> => {
      if (!userProfile) return null;

      try {
        const updatedUser = await client
          .service('users')
          .patch(userProfile._id, { [field]: value });
        setUserProfile(updatedUser);
        return value;
      } catch (err: any) {
        console.error(`Error updating ${field}:`, err);
        setError(`Failed to update ${field}. Please try again later.`);
        return null;
      }
    },
    [client, userProfile],
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="container mx-auto w-full py-4 sm:px-6 lg:px-4 lg:py-6">
          <div className="flex justify-between items-center">Loading...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  if (!userProfile) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div
          className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">User not found</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row">
        {currentUser?._id === userProfile._id && (
          <div className="p-4 pt- inline-block">
            <ProfileSidebar isOwnProfile={true} />
          </div>
        )}
        <div className="flex flex-col flex-1 p-4 pt-0">
          {userProfile.profilePicture && (
            <img
              src={userProfile.profilePicture}
              alt={`${userProfile.username}'s profile`}
              className="w-32 h-32 rounded-full mb-4"
            />
          )}
          <h1 className="text-3xl font-bold mb-4">
            <EditableField
              label=""
              value={userProfile.username}
              onSave={(value) => handleSave('username', value)}
              editable={currentUser?._id === userProfile._id}
            />
          </h1>
          <EditableField
            label="First Name"
            value={userProfile.firstName || 'update your first name'}
            onSave={(value) => handleSave('firstName', value)}
            editable={currentUser?._id === userProfile._id}
          />
          <EditableField
            label="Last Name"
            value={userProfile.lastName || 'update your last name'}
            onSave={(value) => handleSave('lastName', value)}
            editable={currentUser?._id === userProfile._id}
          />
          <EditableField
            label="Email"
            value={userProfile.email}
            onSave={(value) => handleSave('email', value)}
            editable={currentUser?._id === userProfile._id}
          />
          {currentUser?._id === userProfile._id && (
            <EditableField
              label="Profile Picture URL"
              value={userProfile.profilePicture || 'Add a profile picture URL'}
              onSave={(value) => handleSave('profilePicture', value)}
              editable={true}
            />
          )}
          <p className="text-sm text-gray-500 mt-4">
            Member since:{' '}
            {new Date(userProfile.createdAt || '').toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileView;
