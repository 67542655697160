import React from 'react';
import { TeeTime } from '../../types/TeeTime';
import LoadingSpinner from '../LoadingSpinner';
import Pagination from '../Pagination';
import { GolfCourse } from '../../types/GolfCourse';

interface SelectedCourseResultsProps {
  isLoadingTeeTimes: boolean;
  error: string | null;
  currentTees: TeeTime[];
  handleTeeTimeSelect: (teeTime: TeeTime) => void;
  teesPerPage: number;
  availableTeeTimeData: TeeTime[];
  paginate: (pageNumber: number) => void;
  currentPage: number;
  onSelectCourse?: (course: GolfCourse) => void;
  onSelectTeeTime?: (course: GolfCourse, teeTime: TeeTime) => void;
  searchLocation: string;
}

const SelectedCourseResults: React.FC<SelectedCourseResultsProps> = ({
  isLoadingTeeTimes,
  error,
  currentTees,
  handleTeeTimeSelect,
  teesPerPage,
  availableTeeTimeData,
  paginate,
  currentPage,
}) => {
  return (
    <div className="p-4 rounded-lg rounded-t-none">
      {isLoadingTeeTimes ? (
        <LoadingSpinner text="" iconClassName="text-md text-gray-800 my-4" />
      ) : error ? (
        <p>Error: {error}</p>
      ) : currentTees.length > 0 ? (
        <div>
          <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {currentTees.map((teeTime, index) => (
              <button
                key={`${teeTime.externalCourseId || ''}-${index}`}
                onClick={() => handleTeeTimeSelect(teeTime)}
                className="px-2 py-4 text-sm rounded-md bg-white hover:bg-yellow-200 text-gray-800"
              >
                <div className="text-md font-semibold">
                  {teeTime.matchStartDate}
                </div>
                <div className="text-md">
                  ${teeTime.price ? teeTime.price.toFixed(2) : 'N/A'}
                </div>
              </button>
            ))}
          </div>
          <Pagination
            teesPerPage={teesPerPage}
            totalTees={availableTeeTimeData.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      ) : (
        <p className="text-xs text-gray-600 text-center">
          No tee times available for the selected date.
        </p>
      )}
    </div>
  );
};

export default SelectedCourseResults;
