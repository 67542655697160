import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { FaLemon, FaRegUserCircle } from 'react-icons/fa';
import NavUserDropdownMenu from './NavUserDropdownMenu';

const NavBar: React.FC = () => {
  const { user, role, isLoading } = useUser();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  if (isLoading) {
    return (
      <nav className="container mx-auto text-gray-700">
        <div className="container mx-auto w-full py-4 sm:px-6 lg:px-4 lg:py-6"></div>
      </nav>
    );
  }

  return (
    <>
      <nav className="bg-white px-4">
        <div className="container mx-auto md:max-w-5xl py-2 sm:px-6 lg:px-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <Link to="/" className="text-md font-bold flex items-center">
                <div className="flex">
                  <FaLemon className="text-xl text-yellow-100 h-4 w-4 -mr-2" />
                  <FaLemon className="text-xl text-yellow-200 h-4 w-4 -mr-2" />
                  <FaLemon className="text-xl text-yellow-300 h-4 w-4 -mr-2" />
                  <FaLemon className="text-xl text-yellow-400 h-4 w-4 -mr-2" />
                </div>
              </Link>
            </div>
            <ul className="flex space-x-4 text-xs font-semibold">
              {!user && (
                <>
                  <li>coming soon</li>
                </>
              )}
              {user && (
                <>
                  {role === 'admin' && (
                    <li>
                      <Link to="/admin" className="hover:text-gray-200">
                        <div className="flex items-center h-full">
                          Admin Panel
                        </div>
                      </Link>
                    </li>
                  )}
                  <li>
                    <button
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      className="hover:text-yellow-600 flex items-center justify-center h-full"
                    >
                      <div className="flex items-center justify-center">
                        <FaRegUserCircle className="h-5 w-5 text-gray-600" />
                      </div>
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <NavUserDropdownMenu
        isOpen={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
      />
    </>
  );
};

export default NavBar;
