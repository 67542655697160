import React, { useState, useCallback } from 'react';
import GolfCourseSearchInput from './GolfCourseSearchInput';
import GolfCourseList from './GolfCourseList';
import { GolfCourse } from '../../types/GolfCourse';
import { TeeTime } from '../../types/TeeTime';

interface GolfCourseSearchProps {
  onCourseSelect: (course: GolfCourse) => void;
  onTeeTimeSelect: (course: GolfCourse, teeTime: TeeTime) => void;
}

const GolfCourseSearch: React.FC<GolfCourseSearchProps> = ({
  onCourseSelect,
  onTeeTimeSelect,
}) => {
  const [searchLocation, setSearchLocation] = useState('');

  const handleLocationSubmit = useCallback((location: string) => {
    if (location.trim()) {
      setSearchLocation(location);
    }
  }, []);

  return (
    <>
      <div className="sticky top-0 bg-white z-10">
        <GolfCourseSearchInput
          onSubmit={handleLocationSubmit}
          initialValue={searchLocation}
        />
      </div>

      {searchLocation && (
        <div className="container mx-auto">
          <GolfCourseList
            key={searchLocation}
            searchLocation={searchLocation}
            onSelectCourse={onCourseSelect}
            onSelectTeeTime={onTeeTimeSelect}
          />
        </div>
      )}
    </>
  );
};

export default GolfCourseSearch;
