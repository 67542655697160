import React, { useState, useEffect } from 'react';
import { useFeathers } from '../../hooks/useFeathers';
import { getQueryGenerator } from '../../utils/teeTimeQueryGenerators';
import { GolfCourse } from '../../types/GolfCourse';
import { TeeTime } from '../../types/TeeTime';
import LoadingSpinner from '../LoadingSpinner';
import Pagination from '../Pagination';
import { format, addDays, startOfDay, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { formatInTimeZone } from 'date-fns-tz';
import SelectedCourseResults from './SelectedCourseResults';
import { PhoneIcon } from '@heroicons/react/24/outline';

interface GolfCourseListProps {
  searchLocation: string;
  onSelectCourse?: (course: GolfCourse) => void;
  onSelectTeeTime?: (course: GolfCourse, teeTime: TeeTime) => void;
}

const GolfCourseList: React.FC<GolfCourseListProps> = ({
  searchLocation,
  onSelectCourse,
  onSelectTeeTime,
}) => {
  const [courses, setCourses] = useState<GolfCourse[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { client } = useFeathers();
  const [selectedCourse, setSelectedCourse] = useState<GolfCourse | null>(null);
  const [availableTeeTimeData, setAvailableTeeTimeData] = useState<TeeTime[]>(
    [],
  );
  const [isLoadingTeeTimes, setIsLoadingTeeTimes] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [dateOptions, setDateOptions] = useState<string[]>([]);
  const [userTimezone, setUserTimezone] = useState<string>('UTC');
  const [currentPage, setCurrentPage] = useState(1);
  const teesPerPage = 9;
  const [selectedTeeTime, setSelectedTeeTime] = useState<TeeTime | null>(null);
  const [showTeeTimes, setShowTeeTimes] = useState(false);

  const generateDateOptions = (startDate: Date): string[] => {
    return Array.from({ length: 7 }, (_, i) =>
      addDays(startDate, i).toISOString(),
    );
  };

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezone(timezone);
  }, []);

  useEffect(() => {
    const today = startOfDay(toZonedTime(new Date(), userTimezone));
    const options = generateDateOptions(today);
    setDateOptions(options);
    setSelectedDate(options[0]);
  }, [userTimezone]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await client.service('golf-courses').find({
          query: { location: searchLocation },
        });
        if (response.data.length === 0) {
          setError('No golf courses found');
        } else {
          setCourses(response.data);
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch golf courses');
        setLoading(false);
      }
    };

    if (searchLocation) {
      fetchCourses();
    }
  }, [client, searchLocation]);

  const fetchTeeTimes = async (course: GolfCourse) => {
    setIsLoadingTeeTimes(true);
    try {
      const queryGenerator = getQueryGenerator(course.platform.name);
      const queryParams = queryGenerator.generateParams(
        course,
        new Date(selectedDate),
      );

      const response = await client.service('tee-times').find({
        query: {
          ...queryParams,
          platform: course.platform,
        },
      });

      const teeTimesData = Array.isArray(response) ? response : response.data;

      if (Array.isArray(teeTimesData) && teeTimesData.length > 0) {
        const now = new Date();

        const filteredTeeTimes = teeTimesData
          .filter((teeTime) => {
            const teeTimeDate = parseISO(teeTime.matchStartDate);
            return teeTimeDate > now || teeTime.out_of_capacity === true;
          })
          .map((teeTime) => {
            const formattedTime = formatInTimeZone(
              teeTime.matchStartDate,
              'UTC',
              'h:mm a',
            );

            return {
              ...teeTime,
              matchStartDate: formattedTime,
            };
          });

        setAvailableTeeTimeData(filteredTeeTimes);
      } else {
        setAvailableTeeTimeData([]);
      }
    } catch (err) {
      console.error('Error fetching tee times:', err);
      setError('Failed to fetch tee times');
    } finally {
      setIsLoadingTeeTimes(false);
    }
  };

  const handleCourseSelect = (course: GolfCourse) => {
    if (selectedCourse && selectedCourse._id === course._id) {
      setSelectedCourse(null);
      setShowTeeTimes(false);
    } else {
      setSelectedCourse(course);
      if (onSelectCourse) {
        onSelectCourse(course);
      }
    }
    setCurrentPage(1);
  };

  const handleTeeTimeSelect = (teeTime: TeeTime) => {
    setSelectedTeeTime(teeTime);
    if (selectedCourse && onSelectTeeTime) {
      onSelectTeeTime(selectedCourse, teeTime);
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(event.target.value);
    setCurrentPage(1);
  };

  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      if (isNaN(date.getTime())) {
        // If the date is invalid, return the original string
        return dateString;
      }
      const zonedDate = toZonedTime(date, userTimezone);
      return format(zonedDate, 'EEE, MMM d, yyyy');
    } catch (error) {
      console.error('Invalid date string:', dateString);
      return 'Invalid date';
    }
  };

  const indexOfLastTee = currentPage * teesPerPage;
  const indexOfFirstTee = indexOfLastTee - teesPerPage;
  const currentTees = availableTeeTimeData.slice(
    indexOfFirstTee,
    indexOfLastTee,
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const renderSelectedCourseResults = () => (
    <SelectedCourseResults
      isLoadingTeeTimes={isLoadingTeeTimes}
      error={error}
      currentTees={currentTees}
      handleTeeTimeSelect={handleTeeTimeSelect}
      teesPerPage={teesPerPage}
      availableTeeTimeData={availableTeeTimeData}
      paginate={paginate}
      currentPage={currentPage}
      onSelectCourse={onSelectCourse}
      onSelectTeeTime={onSelectTeeTime}
      searchLocation={searchLocation}
    />
  );

  const handleShowTeeTimes = (course: GolfCourse) => {
    setSelectedCourse(course);
    setShowTeeTimes(true);
    fetchTeeTimes(course);
  };

  if (loading) return <div>Loading golf courses...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto md:max-w-5xl">
      <div className="bg-gray-100 border-b sticky top-[60px] z-10 px-4 py-2 my-6 mt-0">
        <div className="flex items-center justify-between">
          <p className="text-xs text-gray-600">
            {courses.length} found for "{searchLocation}"
          </p>
          <select
            value={selectedDate}
            onChange={handleDateChange}
            className="text-sm rounded-md text-gray-600 border-0 hover:cursor-pointer bg-white pr-6 focus:outline-none focus:ring-0"
          >
            {dateOptions.map((date) => (
              <option key={date} value={date}>
                {formatDate(date)}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedCourse && selectedTeeTime && (
        <div className="p-4 m-4 bg-yellow-50 rounded-lg border border-yellow-200">
          <h2 className="text-xs font-semibold mb-2">Selected Tee Time</h2>
          <div className="flex flex-col">
            <p className="text-xs font-normal">{selectedCourse.name}</p>
            <p className="text-xs">
              {selectedDate && formatDate(selectedDate)}
              {' - '}
              {selectedTeeTime?.matchStartDate &&
                formatDate(selectedTeeTime.matchStartDate)}
            </p>
          </div>
        </div>
      )}
      <div className="w-full px-4 mb-6 overflow-x-auto md:overflow-x-auto">
        <ul className="flex md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-4 min-w-full md:min-w-max">
          {courses.map((course) => (
            <li
              key={course._id}
              className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white relative border border-gray-200"
            >
              <div
                className="flex w-full items-center justify-between space-x-6 p-6 cursor-pointer"
                onClick={() => handleCourseSelect(course)}
              >
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="truncate text-lg font-medium text-gray-900">
                      {course.name}
                    </h3>
                  </div>
                  {course.description && (
                    <p className="mt-1 truncate text-sm text-gray-500">
                      {course.description}
                    </p>
                  )}
                </div>
                {/* You can add an image here if you have one for the course */}
                {/* <img alt="" src={course.imageUrl} className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" /> */}
              </div>
              <div>
                <div
                  className={
                    showTeeTimes &&
                    selectedCourse &&
                    selectedCourse._id === course._id
                      ? '-mt-px flex bg-white border-t'
                      : '-mt-px flex bg-white rounded-b-lg border-t'
                  }
                >
                  <div className="flex w-0 flex-1 py-2">
                    <a
                      href={`tel:${course.phoneNumber}`}
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 text-sm font-semibold text-gray-900"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <PhoneIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Call
                    </a>
                  </div>
                  <div className="-ml-px flex w-0 flex-1">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShowTeeTimes(course);
                      }}
                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 text-sm"
                    >
                      Tee Sheet
                    </button>
                  </div>
                </div>
              </div>
              {showTeeTimes &&
                selectedCourse &&
                selectedCourse._id === course._id && (
                  <div className="">{renderSelectedCourseResults()}</div>
                )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GolfCourseList;
