import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';

const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header className="bg-yellow-150 text-gray-700">
        <NavBar />
      </header>
      <main className="flex-grow">
        <Outlet />
      </main>
      <footer className="text-gray-400 text-xs py-8 text-center">
        <p>©2024 lemonware, llc</p>
      </footer>
    </div>
  );
};

export default Layout;
