import React, { useState, useCallback } from 'react';
import { FaSearch } from 'react-icons/fa';

interface GolfCourseSearchInputProps {
  onSubmit: (location: string) => void;
  initialValue: string;
}

const GolfCourseSearchInput: React.FC<GolfCourseSearchInputProps> = ({
  onSubmit,
  initialValue,
}) => {
  const [location, setLocation] = useState(initialValue);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLocation(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (location.trim() !== '') {
      onSubmit(location);
    }
  }, [location, onSubmit]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  return (
    <div className="relative container mx-auto md:max-w-5xl">
      <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
      <input
        type="text"
        id="location"
        name="location"
        value={location}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Search for a course or city"
        className="w-full pl-10 p-4 border-0 bg-transparent rounded-full focus:outline-none focus:ring-0 text-gray-800 text-xl font-semibold"
        autoComplete="off"
      />
    </div>
  );
};

export default GolfCourseSearchInput;
