import { GolfCourse } from '../types/GolfCourse';

interface QueryParams {
  matchStartDate: string;
  platform: {
    name: string;
    clubId: string;
    courseId: string;
  };
  externalCourseId?: string;
  [key: string]: any;
}

interface QueryGenerator {
  generateParams(course: GolfCourse, date: Date): QueryParams;
}

class BaseQueryGenerator implements QueryGenerator {
  generateParams(course: GolfCourse, date: Date): QueryParams {
    return {
      matchStartDate: date.toISOString().split('T')[0], // Format as YYYY-MM-DD
      platform: {
        name: course.platform.name,
        clubId: course.platform.clubId,
        courseId: course.platform.courseId,
      },
    };
  }
}

class PremierGolfQueryGenerator extends BaseQueryGenerator {
  generateParams(course: GolfCourse, date: Date): QueryParams {
    return {
      ...super.generateParams(course, date),
      externalCourseId: course.platform.courseId,
    };
  }
}

class ChronogolfQueryGenerator extends BaseQueryGenerator {
  generateParams(course: GolfCourse, date: Date): QueryParams {
    return {
      ...super.generateParams(course, date),
      // Add any Chronogolf specific parameters here
    };
  }
}

const queryGenerators: { [key: string]: QueryGenerator } = {
  PremierGolf: new PremierGolfQueryGenerator(),
  Chronogolf: new ChronogolfQueryGenerator(),
  default: new BaseQueryGenerator(),
};

export function getQueryGenerator(platformName: string): QueryGenerator {
  return queryGenerators[platformName] || queryGenerators.default;
}
