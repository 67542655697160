import React from 'react';
import { FaLemon } from 'react-icons/fa';

interface LoadingSpinnerProps {
  text?: string;
  iconClassName?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  text = 'Loading...',
  iconClassName = '',
}) => {
  return (
    <div className={`flex justify-center items-center h-full`}>
      <p className="text-xs flex items-center">
        <FaLemon
          className={`inline-block mr-2 animate-spin text-yellow-400 ${iconClassName}`}
        />
        <FaLemon
          className={`inline-block mr-2 animate-spin text-yellow-300 ${iconClassName}`}
        />
        <FaLemon
          className={`inline-block mr-2 animate-spin text-yellow-200 ${iconClassName}`}
        />
        {text}
      </p>
    </div>
  );
};

export default LoadingSpinner;
