import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
  useCallback,
} from 'react';
import { useFeathers } from '../hooks/useFeathers';
import { createClient } from '../feathersClient';

const client = createClient();

type UserRole = 'guest' | 'user' | 'admin';

interface User {
  _id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  username: string;
  role?: 'user' | 'admin';
  profilePicture?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  role: UserRole;
  setRole: (role: UserRole) => void;
  logout: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  isLoading: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<UserRole>('guest');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedRole = localStorage.getItem('role');
    const storedToken = localStorage.getItem('accessToken');
    if (storedUser && storedRole && storedToken) {
      client
        .authenticate({
          strategy: 'jwt',
          accessToken: storedToken,
        })
        .then(({ user }) => {
          setUser(user);
          setRole(storedRole as UserRole);
        })
        .catch(() => {
          // If token is invalid, clear the stored data
          localStorage.removeItem('user');
          localStorage.removeItem('role');
          localStorage.removeItem('accessToken');
          setUser(null);
          setRole('guest');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const logout = async () => {
    await client.logout();
    setUser(null);
    setRole('guest');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('accessToken');
  };

  const login = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      const { user, accessToken } = await client.authenticate({
        strategy: 'local',
        email,
        password,
      });
      setUser(user);
      setRole(user.role || 'user');
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('role', user.role || 'user');
      localStorage.setItem('accessToken', accessToken);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, role, setRole, logout, login, isLoading }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
